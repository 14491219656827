@import '../../../../scss/theme-bootstrap';

.gnav-section-formatter {
  @include breakpoint($landscape-up) {
    background-color: $color-white;
  }
  &--close-icon {
    height: 15px;
    .icon--close {
      width: 15px;
      height: inherit;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    opacity: 1;
    padding: $gnav-mobile-hpad--outer 0;
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      background-color: $color-white;
      display: block;
      max-height: $gnav-content-max-height;
      overflow-y: auto;
      padding: 26px 40px;
      position: fixed;
    }
    // gnav section hidden state
    .gnav-section-formatter[aria-expanded='false'] & {
      flex-direction: column;
      flex-basis: auto;
      height: 0;
      opacity: 0;
      position: absolute;
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__gnav-links-wrapper {
    display: flex;
    flex-direction: column;
    height: 0;
    order: 2;
    width: 100%;
    @include breakpoint($landscape-up) {
      column-gap: 40px;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
      position: relative;
      order: unset;
    }
    .gnav-section-formatter--hide-touts & {
      .gnav-tout {
        display: none;
        @include breakpoint($landscape-up) {
          display: inherit;
        }
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 1;
    }
    .gnav-section-formatter[aria-expanded='true'] & {
      height: auto;
    }
    // default expanded layout is grid-1 from cms selection
    // .gnav-section-formatter--grid-1 (6 even columns)
    > div {
      padding: 0;
    }
    > .gnav-tout {
      padding: $gnav-mobile-hpad--outer 0;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 0 0 50px);
        flex: 0 20%;
        margin-#{$ldirection}: auto;
      }
    }
    > .gnav-links {
      &.gnav-links--columns-second-break {
        @include breakpoint($landscape-up) {
          flex: 0 75%;
        }
        @include breakpoint($largest-up) {
          flex: 0 60%;
        }
        @include breakpoint($xlarge-up) {
          flex: 0 50%;
        }
        html:lang(ko-KR) & {
          @include breakpoint($landscape-up) {
            flex: 0 44%;
          }
          @include breakpoint($largest-up) {
            flex: 0 34%;
          }
          @include breakpoint($extra-xlarge-up) {
            flex: 0 28%;
          }
        }
      }
      &:not(.gnav-links--columns-break) {
        @include breakpoint($landscape-up) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  &__tout {
    padding: 0 $gnav-mobile-hpad--outer;
    order: 1;
    @include breakpoint($landscape-up) {
      display: none;
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 2;
      padding-top: 0;
    }
  }
}
